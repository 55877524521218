<template>
  <div class="branch content_area">
    <el-image
        style="width: 100%; height: 100%"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/aabbcc.png"
        fit="scale-down"></el-image>
  </div>
</template>

<script>
export default {
  name: "branch"
}
</script>

<style scoped lang="less">
.branch {
  padding: 30px;
}
</style>